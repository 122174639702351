.deck > div {
  background-size: auto 95%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 250px;
  height: 85vh;
  max-height: 385px;
  border-radius: 10px;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}
